import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import React from 'react';
import Form from 'react-bootstrap/Form';

import Image from 'react-bootstrap/Image';
import shape1 from '../assets/images/shape1.png'
import shape2 from '../assets/images/shape2.png'
import { Row, Col, Container, Button, } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import offerImageOne from '../assets/images/offerBoxImageOne.png'
import offerImageTwo from '../assets/images/offerBoxImageTwo.png'
import offerImageThree from '../assets/images/offerBoxImageThree.png'
import offerImageFour from '../assets/images/offerBoxImageFour.png'
import offerImageFive from '../assets/images/offerBoxImageFive.png'
import offerImageSix from '../assets/images/offerBoxImageSix.png'
import offerImageSeven from '../assets/images/offerBoxImageSeven.png'
import offerImageEight from '../assets/images/offerBoxImageEight.png'
import arrow from '../assets/images/right-arrow.png'
import leftImageOne from '../assets/images/left-image-one.png'
import quote from '../assets/images/quote.png'
import clientAdidas from '../assets/images/client-adidas.png'
import clientReebok from '../assets/images/client-reebok.png'
import clientMetropolis from '../assets/images/client-metropolis.png'
import strategyAndConsultingIcon from '../assets/images/Strategy-and-Consulting-icon.png'
import Implementation from '../assets/images/Implementation.png'
import ManagedServices from '../assets/images/Managed-Services.png'


// Importing client_1 to client_27, all pointing to the same file
// Importing client_1 to client_41 from the "brands" folder
import client_1 from "../assets/images/clients/brands/adidas.png";
import client_2 from "../assets/images/clients/brands/reebok.png";
import client_3 from "../assets/images/clients/brands/marks.png";
import client_4 from "../assets/images/clients/brands/BIBA.png";
import client_5 from "../assets/images/clients/brands/rel.png";
import client_6 from "../assets/images/clients/brands/lulu.png";
import client_7 from "../assets/images/clients/brands/softbella.png";
import client_8 from "../assets/images/clients/brands/kotak.png";
import client_9 from "../assets/images/clients/brands/rblbank.png";
import client_10 from "../assets/images/clients/brands/standard-life.png";
import client_11 from "../assets/images/clients/brands/hdfc.png";
import client_12 from "../assets/images/clients/brands/mudra.png";
import client_13 from "../assets/images/clients/brands/ambit.png";
import client_14 from "../assets/images/clients/brands/great.png";
import client_15 from "../assets/images/clients/brands/metropolis.png";
import client_16 from "../assets/images/clients/brands/isstock.png";
import client_17 from "../assets/images/clients/brands/kaya.png";
import client_18 from "../assets/images/clients/brands/thyrocare.png";
import client_19 from "../assets/images/clients/brands/ph-dignostics.png";
import client_20 from "../assets/images/clients/brands/agilus.png";
import client_21 from "../assets/images/clients/brands/tata-motors.png";
import client_22 from "../assets/images/clients/brands/royal-enfield.png";
import client_23 from "../assets/images/clients/brands/AirAsia.png";
import client_24 from "../assets/images/clients/brands/l&t.png";
import client_25 from "../assets/images/clients/brands/kem.png";
import client_26 from "../assets/images/clients/brands/keous.png";
import client_27 from "../assets/images/clients/brands/thane-municipal.png";
import client_28 from "../assets/images/clients/brands/republic-of-rwanda.png";
import client_29 from "../assets/images/clients/brands/die-bundersregierung.png";
import client_30 from "../assets/images/clients/brands/Pune-municipal.png";
import client_31 from "../assets/images/clients/brands/Karnataka-Tourism.png";
import client_32 from "../assets/images/clients/brands/ladakh.png";
import client_33 from "../assets/images/clients/brands/house-of-abhinandan.png";
import client_34 from "../assets/images/clients/brands/zeemedia.png";
import client_35 from "../assets/images/clients/brands/abp-news.png";
import client_36 from "../assets/images/clients/brands/panjab.png";
import client_37 from "../assets/images/clients/brands/living.png";
import client_38 from "../assets/images/clients/brands/cinf.png";
import client_39 from "../assets/images/clients/brands/ZIP-DAVIS.png";



import formBoxAfterImage from '../assets/images/Layer_1.png';
import { IoIosStar } from "react-icons/io";
import caseStudy from '../assets/images/case-study.png';
import LayerTwo from '../assets/images/layers/Layer_2.png';
import LayerThree from '../assets/images/layers/Layer_3.png';
import LayerFour from '../assets/images/layers/Layer_4.png'
import bannerShape1 from '../assets/images/layers/banner_layer_one.png';
import bannerShape2 from '../assets/images/layers/banner_layer_two.png';
import { sendEmail } from "../emailService";  // Importing the email service

const clientImages = [
    client_1, client_2, client_3, client_4, client_5, client_6, client_7,
    client_11, client_12, client_13, client_14, client_21, client_22, client_23, client_26, client_27, client_28, client_29, client_30, client_31, client_32, client_33, client_34, client_35, client_36, client_37
];


const Home = () => {
    const [isFluid, setIsFluid] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            // Switch to 'container' if the width is less than a threshold (e.g., 1400px)
            setIsFluid(window.innerWidth < 1400);
        };

        // Initial check on mount
        handleResize();

        // Listen for window resize
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 992, // Tablets and below
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768, // Mobile devices
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    /*contact form*/
        const [formData, setFormData] = useState({
            name: "",
            email: "",
            mobile: "",
            company: "",
            message: "",
            role: "",      // New role field
            subscribe: "yes",  // New subscription field
            heardAbout: "",
            jobTitle: "",
            lastName: "",
        });
    
        const [loading, setLoading] = useState(false);
        const [responseMessage, setResponseMessage] = useState("");
    
        // Email validation regex (blocks free providers)
        const businessEmailRegex = /@(gmail\.com|yahoo\.com|outlook\.com|hotmail\.com|aol\.com|icloud\.com|protonmail\.com|zoho\.com|mail\.com|gmx\.com)$/i;
    
        // Handle input changes
        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };
    
        // Handle form submission
        const handleSubmit = async (e) => {
            e.preventDefault();
            setLoading(true);
            setResponseMessage("");
    
            const { name, email, mobile, company, message,} = formData;
    
            // Validate required fields
            if (!name ||  !email || !mobile || !company ||  !message ) {
                setResponseMessage("Please fill in all required fields.");
                setLoading(false);
                return;
            }
    
            // Validate business email
            if (businessEmailRegex.test(email)) {
                setResponseMessage("Please enter a valid business email address.");
                setLoading(false);
                return;
            }
    
            // Email content
            const emailHtml = `
               <table role="presentation" style="width:100%; border-collapse: collapse; border: 1px solid #ddd;">
      <tr>
        <td colspan="2" style="background-color:#f2f2f2; padding:10px; font-weight:bold; text-align:center;">
          Contact Us - Enquiry Form
        </td>
      </tr>
      <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>First Name:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${name}</td>
      </tr>
      <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Email:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${email}</td>
      </tr>
      <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Mobile Number:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${mobile}</td>
      </tr>
      <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Company:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${company}</td>
      </tr>
      <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Message:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${message}</td>
      </tr>
    </table>
    
            `;
    
            try {
                const response = await sendEmail("sakshid@comsenseconsulting.com", "Contact Us - Enquiry Form", emailHtml);
                if (response.success) {
                    setResponseMessage("Form submitted successfully!");
    
                    // Clear the message after 3 seconds
                    setTimeout(() => {
                        setResponseMessage("");
                    }, 3000);
    
                    // setFormData({ name: "", email: "", mobile: "", company: "", message: "" });
                    setFormData({
                        name: "",
                        email: "",
                        mobile: "",
                        company: "",
                        message: "",
                    });
                } else {
                    setResponseMessage("Failed to send email. Please try again.");
                }
            } catch (error) {
                setResponseMessage("An error occurred. Please try again.");
            }
    
            setLoading(false);
        };
    
    return (
        <>
            <header class="masthead z-0">
                <div class="container text-center">
                    <Image src={bannerShape1} className='z-n1 position-absolute top-0 start-0 w-40 d-none d-md-block' />
                    <div class="masthead-subheading mb-3 fw-500 text-252B42">Accelerating your growth journey</div>
                    <div class="masthead-heading fw-700 text-252B42">We’re Your <span style={{ color: '#ee0000' }}>#1</span> Trusted Partners</div>
                    <div class="masthead-heading fw-700 text-252B42">for Everything <span style={{ color: '#0895cc' }}>Sales<span style={{ fontStyle: 'italic' }}>f</span>orce</span> !</div>
                    <p className='mt-4 mb-5'>We empower businesses to achieve data-driven growth and unmatched customer engagement. </p>
                    <Nav.Link className="d-inline text-white btn btn-danger btn-xl me-3" as={Link} to="../contact">Contact Us Now</Nav.Link>
                    <Image src={bannerShape2} className='z-n1 position-absolute bottom-0 end-0 w-40 opacity-25 d-none d-md-block' />
                </div>
            </header>

            <section class="page-section pt-0 ">
                {/* <Container fluid={isFluid ? true : undefined}> */}
                <Container fluid>
                    <Row className="h-100 mt-4 mt-lg-0 mt-md-0">
                        {/* Column 1 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column mb-5 mb-sm-0">
                            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center text-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2"><span className='bordered-text bordered-text-start'>What</span> We Offer</h2>

                            </div>
                        </Col>

                        {/* Column 2 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1 mb-3 mb-md-0">
                                <Col lg={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3>Data Cloud</h3>
                                            <p className="text-left">
                                                Unify data for personalized marketing and a single customer view.
                                            </p>
                                        </div>
                                        <Nav.Link as={Link} to="../data-cloud"><Image src={arrow} className="arrow mt-2 align-self-start" /></Nav.Link>
                                    </div>
                                </Col>
                                <Col lg={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageOne} />
                                </Col>
                            </Row>
                        </Col>

                        {/* Column 3 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1">
                                <Col lg={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Marketing Cloud</h3>
                                            <p className="text-left">
                                                Automate journeys to connect seamlessly across channels.
                                            </p>
                                        </div>
                                        <Nav.Link as={Link} to="../marketing-cloud"><Image src={arrow} className="arrow mt-2 align-self-start" /></Nav.Link>
                                    </div>
                                </Col>
                                <Col lg={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageTwo} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="h-100 mt-3">
                        {/* Column 1 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column  mb-3 mb-sm-0">
                            <Row className="h-100 flex-grow-1">
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageThree} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Marketing Cloud Personalization</h3>
                                            <p className="text-left text-break">
                                                Deliver real-time experiences where it matters most.
                                            </p>
                                        </div>
                                        <Nav.Link as={Link} to="../marketing-cloud-personalization"><Image src={arrow} className="arrow mt-2 align-self-start" /></Nav.Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        {/* Column 2 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1">
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageFour} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Loyalty Management</h3>
                                            <p className="text-left">
                                                Nurture relationships and create lasting loyalty with your customers
                                            </p>
                                        </div>
                                        <Image src={arrow} className="arrow mt-2 align-self-start" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="h-100 mt-3">
                        {/* Empty Column for Large Screens Only */}
                        <Col lg={4} className="d-lg-block d-none">
                            <div className="text-center h-bx d-flex align-items-center justify-content-center"></div>
                        </Col>

                        {/* Column 1 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1 mb-3 mb-sm-0">
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Tableau</h3>
                                            <p className="text-left">Discover answers to business-critical questions with a holistic, real-time view of your relevant data.</p>
                                        </div>
                                        <Image src={arrow} className="arrow mt-2 align-self-start" />
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageFive} />
                                </Col>
                            </Row>
                        </Col>

                        {/* Column 2 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1">
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Agentforce</h3>
                                            <p className="text-left">Enable agents with tools to drive productivity and impact.</p>
                                        </div>
                                        <Nav.Link as={Link} to="../agent-force"><Image src={arrow} className="arrow mt-2 align-self-start" /></Nav.Link>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageSix} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="h-100 mt-3">
                        {/* Column 1 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1  mb-3 mb-sm-0">
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageSeven} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Service Cloud</h3>
                                            <p className="text-left">Empower agents to provide seamless, omnichannel support.</p>
                                        </div>
                                        <Image src={arrow} className="arrow mt-2 align-self-start" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        {/* Column 2 */}
                        <Col lg={4} md={6} sm={6} xs={12} className="d-flex flex-column">
                            <Row className="h-100 flex-grow-1">
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex align-items-stretch">
                                    <Image className="w-100 h-100 object-fit-cover" src={offerImageEight} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="d-flex flex-column">
                                    <div className="offer-box d-flex flex-column flex-grow-1">
                                        <div className="mt-auto">
                                            <h3 className="text-break">Sales Cloud</h3>
                                            <p className="text-left">Track metrics in real time for agile business decisions.</p>
                                        </div>
                                        <Image src={arrow} className="arrow mt-2 align-self-start" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        {/* Empty Column for Large Screens Only */}
                        <Col lg={4} className="d-lg-block d-none">
                            <div className="text-center h-bx d-flex align-items-center justify-content-center"></div>
                        </Col>
                    </Row>

                </Container>
            </section>


            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={11}>
                                                    <Row className='mb-4'>
                                                        <Col lg={12} className='mb-3'>
                                                            <span className='fw-600 ls-minus036 fancy-text3 ps-2 d-block mb-0 pe-3 z-0 text-EE0000  position-relative overflow-hiddens text-white fs-20' style={{ color: '#EE0000 !important' }}>Comsense in Numbers​</span>
                                                        </Col>
                                                    </Row>
                        
                            <Row className='align-items-center mt-0 justify-content-start testimonials-area position-relative'>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>100+</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-067BB1 border-dark border-top'>Man Years Experience​</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>4</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top' style={{ borderTop: ' 1px solid #067BB1' }}>Global Locations​</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>45+</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 width-fit pe-4 HelveticaRegular border-1 border-252B42 border-dark border-top'>Global Clients​</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>Power of 3​</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top'>Capabilities | Products | Managed Ops​​</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mt-3 justify-content-start testimonials-area position-relative'>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>100+</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-067BB1 border-dark border-top'>Team Strength​</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>10+ Yrs </h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top' style={{ borderTop: ' 1px solid #067BB1' }}>of Martech Experience​</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>100+​</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 width-fit pe-4 HelveticaRegular border-1 border-252B42 border-dark border-top'>Salesforce Certificates​</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className='cus-col-sm text-left'>
                                    <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                        <h5 className='text-252B42 mb-3 fw-700 fs-7'>Top Partners​</h5>
                                        <p className='text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top'>of Salesforce​</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>



            <section class="bg-E2F1FE pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">What <span className='bordered-text'>sets us</span> apart?</h2>

                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-4'>
                        <Col className='' lg={6} md={12}>
                            <Image className='w-100' src={leftImageOne} />
                        </Col>
                        <Col className='px-3 px-lg-5 pt-3 pt-lg-0' lg={6} md={12}>
                            <div className='sets-list'>
                                <h3 className='mt-1'>Salesforce is our playground</h3>
                                <p className='text-left mb-1'>We know it, love it, and master it.</p>
                            </div>
                            <div className='sets-list'>
                                <h3 className='mt-1'>Data is our superpower</h3>
                                <p className='text-left mb-1'>We turn your data into your biggest advantage.</p>
                            </div>
                            <div className='sets-list'>
                                <h3 className='mt-1'>We take Ownership</h3>
                                <p className='text-left mb-1'>Your challenges become ours until we deliver results.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section class="page-section bg-white">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center position-relative">
                                <h2 class="section-heading section-heading-center">Hear from Our Happy Customers </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-4 testimonials-area position-relative'>
                        <Col lg={12}>
                            <Image className='quote-icon' src={quote} />
                        </Col>
                        <Col lg={4} className='mb-3 mb-lg-0'>
                            <div className='client-quote'>
                                <div className='client-review'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <p>It has been a great journey with Comsense so far. The team is really supportive and helps us with regular insights to improve our ROI. There have been instances when they have gone out of their way to ensure that our campaigns are delivered on time. I look forward to a long and successful relationship with them. </p>
                                <div className='client-detail d-flex align-items-center justify-space-between'>
                                    <div>
                                        <h5>Vaibhav Bhardwaj</h5>
                                        <p>Brand Activation Manager Reebok India</p>
                                    </div>
                                    <Image src={clientReebok} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className='mb-3 mb-lg-0'>
                            <div className='client-quote'>
                                <div className='client-review'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <p>It’s been a wonderful experience working with Comsense so far. The team provides updates & insights on a regular basis which is really helpful. The team is really supportive and ensures precise execution of campaigns in a timely manner. I wish them the very best & look forward to a long and successful relationship with them. </p>
                                <div className='client-detail d-flex align-items-center justify-between'>
                                    <div>
                                        <h5>Ankit Bhatnagar</h5>
                                        <p>Head CRM & Membership Adidas (India)</p>
                                    </div>
                                    <Image src={clientAdidas} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className='mb-3 mb-lg-0'>
                            <div className='client-quote'>
                                <div className='client-review'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <p>It has been a great journey with Comsense so far. The team is really supportive and helps us with regular insights to improve our ROI. There have been instances when they have gone out of their way to ensure that our campaigns are delivered on time. I look forward to a long and successful relationship with them. </p>
                                <div className='client-detail d-flex align-items-center justify-space-between'>
                                    <div>
                                        <h5>Vaibhav Bhardwaj</h5>
                                        <p>Brand Activation Manager Reebok India</p>
                                    </div>
                                    <Image src={clientMetropolis} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className="bg-white pt-80 pb-80">
                <Container>
                    <Row className="align-items-center mb-0">
                        <Col xs={12}>
                            <div className="text-center d-flex align-items-center justify-content-center position-relative">
                                <h2 class="section-heading section-heading-center fw-700 fs-2 mb-4 mb-sm-0">Hear from Our <span className='bordered-text'>Happy</span> Customers</h2>

                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center testimonials-area position-relative">
                        <Col lg={12}>
                            <Image className="quote-icon" src={quote} />
                        </Col>
                        <Col lg={12} className="mt-1">
                            <Slider {...settings} className="mt-4">
                                {/* Testimonial 1 */}
                                <div className="px-sm-3 px-1 slide d-flex flex-column h-100">
                                    <div className="client-quote d-flex flex-column h-100">
                                        <div className="client-review">
                                            {[...Array(5)].map((_, i) => (
                                                <IoIosStar key={i} />
                                            ))}

                                        </div>
                                        <p className="flex-grow-1">
                                            It has been a great journey with Comsense so far. The team is really supportive and helps us with regular insights to improve our ROI. There have been instances when they have gone out of their way to ensure that our campaigns are delivered on time. I look forward to a long and successful relationship with them.
                                        </p>
                                        <div className="client-detail d-flex align-items-center justify-content-between">
                                            <div>
                                                <h5>Vaibhav Bhardwaj</h5>
                                                <p>Brand Activation Manager, Reebok India</p>
                                            </div>
                                            <Image src={clientReebok} />
                                        </div>
                                    </div>
                                </div>

                                {/* Testimonial 2 */}
                                <div className="px-sm-3 px-1 slide d-flex flex-column h-100">
                                    <div className="client-quote d-flex flex-column h-100">
                                        <div className="client-review">
                                            {[...Array(5)].map((_, i) => (
                                                <IoIosStar key={i} />
                                            ))}
                                        </div>
                                        <p className="flex-grow-1">
                                            It’s been a wonderful experience working with Comsense so far. The team provides updates & insights on a regular basis which is really helpful. The team is really supportive and ensures precise execution of campaigns in a timely manner. I wish them the very best & look forward to a long and successful relationship with them.
                                        </p>
                                        <div className="client-detail d-flex align-items-center justify-content-between">
                                            <div>
                                                <h5>Ankit Bhatnagar</h5>
                                                <p>Head CRM & Membership, Adidas (India)</p>
                                            </div>
                                            <Image src={clientAdidas} />
                                        </div>
                                    </div>
                                </div>

                                {/* Testimonial 3 */}
                                <div className="px-sm-3 px-sm-1 slide d-flex flex-column h-100">
                                    <div className="client-quote d-flex flex-column h-100">
                                        <div className="client-review">
                                            {[...Array(5)].map((_, i) => (
                                                <IoIosStar key={i} />
                                            ))}
                                        </div>
                                        <p className="flex-grow-1">
                                            Theirs is an excellent team of results-driven data scientists who not only understand client needs but are also very proactive. Across all the touch points the quality of Comsense has been of very high standards. They are the first firm I call for any data science-related projects.
                                        </p>
                                        <div className="client-detail d-flex align-items-center justify-content-between">
                                            <div>
                                                <h5>Amit Goel</h5>
                                                <p>Ex-CIO Metropolis Healthcare</p>
                                            </div>
                                            <Image src={clientMetropolis} />
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="bg-E2F1FE pt-80 pb-80">
                <Container>
                    <Row className='align-items-center mb-5 align-items-center justify-center' style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Col lg={8}>
                            <div class="text-center d-flex align-items-center justify-content-center position-relative">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">We’ve Got the Expertise to Help You Move <span className='bordered-text'>Faster</span> with <span style={{ color: '#0895cc' }}>Sales<span style={{ fontStyle: 'italic' }}>f</span>orce</span> </h2>

                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-6 testimonials-area position-relative'>
                        <Col lg={4} md={6}>
                            <div className='help-box help-box-height mb-5 mb-lg-0 ps-3 pe-3'>
                                <div className='help-icon'>
                                    <Image className='' src={strategyAndConsultingIcon} />
                                </div>
                                <h5 className='mb-3 mt-2'>Strategy & Consulting</h5>
                                <p>With deep Salesforce expertise, our consultants guide you in choosing the best solutions to maximize your success. </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='help-box  help-box-height mb-5 mb-lg-0'>
                                <div className='help-icon'>
                                    <Image className='' src={Implementation} />
                                </div>
                                <h5 className='mb-3 mt-2'>Implementation</h5>
                                <p>We ensure smooth, end-to-end implementation & scalable solutions that will leave you set up for success.</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='help-box  help-box-height'>
                                <div className='help-icon'>
                                    <Image className='' src={ManagedServices} />
                                </div>
                                <h5 className='mb-3 mt-2'>Managed Services</h5>
                                <p>Our expert team becomes your trusted partner, managing and optimizing Salesforce to keep everything running seamlessly.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section class="page-section bg-white pt-80 pb-80">
                <Container>
                    <Row className='align-items-center mb-5 align-items-center justify-center' style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Col lg={8}>
                            <div class="text-center d-flex align-items-center justify-content-center position-relative">
                                <h2 class="section-heading section-heading-center fw-700 fs-2"><span className='bordered-text'>Case Studies</span> </h2>

                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mb-2'>
                        <Col lg={12}>

                            <div className='bg-E2F1FE box-shadow-one rounded-4'>
                                <Row className='align-items-center'>
                                    <Col lg={7} md={12}>
                                        <div className="p-23">
                                            <h4 className='mb-3 pb-0 ps-6 fs-26 lh-13'>Igniting a Global Sporting Revolution with <span className='text-ee0000'>CDP-Readiness</span></h4>
                                            <p className='ls-minus036 mb-2 fs-6 fw-600 text-252B42'>A global leader in the sports apparel industry struggled to effectively maintain customer data. They intended to:</p>
                                            <ul className='ps-2 mb-4 custom-ul'>
                                                <li className='ls-minus036 fs-12 mb-1 fw-400'>Integrate and utilize customer data from their diverse, distributed market</li>
                                                <li className='ls-minus036 fs-12 mb-1 fw-400'>Optimize global customer data management</li>
                                                <li className='ls-minus036 fs-12 mb-1 fw-400'>Refine marketing strategies</li>
                                            </ul>

                                            <Nav.Link className="d-inline-block  text-white btn btn-danger btn-xl me-3 ls-0 fw-600" as={Link} to="../contact">Contact Us Now</Nav.Link>

                                            
                                        </div>
                                    </Col>
                                    <Col lg={5} md={12} className='pe-3 pe-lg-0'>
                                        <div className=''>
                                            <Image src={caseStudy} className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>



                </Container>
            </section>

            <section class="page-section bg-blue-gradient-two position-relative z-0 pt-80 pb-80">
                <Image src={LayerThree} className="position-absolute top-0 start-0 w-40" />
                <Container>
                    <Row className='align-items-center mb-5 align-items-center justify-center' style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Col lg={8}>
                            <div class="text-center d-flex align-items-center justify-content-center position-relative">
                                <h2 style={{ textShadow: '0px 4px 15px 0px #00000040;' }} class="section-heading section-heading-center fw-700 fs-2 text-white">We’re Trusted by <span className='bordered-text'>Leading</span> Global Brands </h2>

                            </div>
                        </Col>
                    </Row>

                    <Row className='align-items-center mt-1 testimonials-area position-relative'>
                        {/* <div className='client-area'>
                       <Image src={clientOne} />
                       <Image src={clientTwo} />
                       <Image src={clientThree} />
                       <Image src={clientTwo} />
                       </div> */}
                        <div className='client-area logo-container'>
                            {clientImages.map((src, index) => (
                                <Image className='client-logo' key={index} src={src} alt={`Client ${index + 1}`} />
                            ))}
                        </div>
                    </Row>
                </Container>
                <Image src={LayerTwo} className="position-absolute bottom-0 end-0 w-40" />

            </section>

            <section class="page-section bg-white pt-80 pb-80">

                <Container>
                    <div className="bg-E2F1FE position-relative box-shadow-one rounded-4 pt-5 p-23 p-sm-23">
                        <Image src={LayerFour} className="position-absolute bottom-0 start-0 w-40" />

                        <Row className='position-relative form-box z-0'>

                            <Col lg={5}>
                                <div class="text-left d-flex  justify-content-start">
                                    <h2 class="section-heading fw-700 fs-2">Ready to level up? <span className='text-ee0000'>Let’s chat</span> and make it <span className='bordered-text bordered-text-start'>happen! </span></h2>
                                </div>
                            </Col>
                            <Col lg={7}>
                             <Form onSubmit={handleSubmit}>
                                <Row className='pb-3 mt-3 mt-lg-0'>
                                    <Col lg={6}>
                                        <>
                                            <Form.Label htmlFor="inputPassword5">First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                            />
                                        </>
                                    </Col>
                                    <Col lg={6}>
                                        <>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                            />
                                        </>
                                    </Col>
                                    <Col lg={6}>
                                        <>
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleChange}
                                            />
                                        </>
                                    </Col>
                                    <Col lg={6}>
                                        <>
                                            <Form.Label htmlFor="CompanyName">Company Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="company"
                                                value={formData.company}
                                                onChange={handleChange}
                                          
                                            />
                                        </>
                                    </Col>
                                    <Col lg={12}>
                                        <>
                                            <Form.Label htmlFor="Message">Message</Form.Label>
                                            <Form.Control
                                                  name="message"
                                                  value={formData.message}
                                                  onChange={handleChange}
                                    
                                                as="textarea" rows={4}
                                            />
                                        </>
                                    </Col>
                                    <Col lg={12} className='mt-4 text-start'>
                                        <>
                                            {/* <a class="btn btn-danger btn-xl me-3 ls-0 fw-600" href="#">Submit</a> */}
                                            <Button
                                                    variant=""
                                                    type="submit"
                                                    className="fw-600 d-block btn btn-danger btn-xl"
                                                    disabled={loading}
                                                >
                                                    {loading ? "Submitting..." : "Submit"}
                                                </Button>
                                        </>
                                    </Col>
                                    {responseMessage && (
                                            <p className="mt-3 mb-0 fs-12 text-center" style={{ color: loading ? "blue" : "red" }}>
                                                {responseMessage}
                                            </p>
                                        )}
                                </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>


        </>


    );
};

export default Home;
