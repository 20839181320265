import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons
import Logo from '../../assets/images/black-logo.png';
import { CiMenuFries } from "react-icons/ci";

const Header = () => {
  const [show, setShow] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false); // State for Services dropdown
  const [solutionsOpen, setSolutionsOpen] = useState(false); // State for Solutions dropdown

  const location = useLocation(); // Hook to get the current location

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    
  // Scroll to top whenever the location changes
  window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  
  return (
    <>
      {/* Main Navbar */}
      <Navbar collapseOnSelect expand="lg" sticky="top" className={`navbar_menu ${scrolled ? "navbar-scrolled" : ""}`}>
        <Container>
          <Navbar.Brand href="/">
            <Image width={150} src={Logo} />
          </Navbar.Brand>
          {/* Mobile Offcanvas Toggle Button */}
          <div className="d-lg-none">
            <Button variant="light" onClick={handleShow}>
              <CiMenuFries />
            </Button>
          </div>
          {/* Desktop Menu */}
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link as={Link} to="/" active={location.pathname === "/"}>Home</Nav.Link>
              {/* <NavDropdown
                title={
                  <>
                    Services{' '}
                    {servicesOpen ? (
                      <FaChevronUp className="dropdown-icon" />
                    ) : (
                      <FaChevronDown className="dropdown-icon" />
                    )}
                  </>
                }
                id="collapsible-nav-dropdown"
                className={`nav-dropdown-menu ${[
                    "/strategyAndConsulting",
                    "/implementation",
                    "/customIntegrations",
                    "/designAndArchitecture",
                    "/data"
                  ].includes(location.pathname) ? "active" : ""
                  }`}
                onToggle={(isOpen) => setServicesOpen(isOpen)} // Update state on toggle
              >
                <NavDropdown.Item
                  as={Link} to="/strategyAndConsulting"
                  active={location.pathname === "/strategyAndConsulting"}
                >
                  Strategy & Consulting
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/implementation"
                  active={location.pathname === "/implementation"}
                >
                  Implementation
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/customIntegrations"
                  active={location.pathname === "/customIntegrations"}
                >
                  Custom Integrations
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/designAndArchitecture"
                  active={location.pathname === "/designAndArchitecture"}
                >
                  Design & Architecture
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/data"
                  active={location.pathname === "/data"}
                >
                  Data
                </NavDropdown.Item>
              </NavDropdown> */}


              <NavDropdown
                title={
                  <>
                    Services{' '}
                    {solutionsOpen ? (
                      <FaChevronUp className="dropdown-icon" />
                    ) : (
                      <FaChevronDown className="dropdown-icon" />
                    )}
                  </>
                }
                id="collapsible-nav-dropdown"
                className={`nav-dropdown-menu ${[
                  "/data-cloud",
                  "/marketing-cloud",
                  "/marketing-cloud-personalization",
                  "/agent-force",
                ].includes(location.pathname) ? "active" : ""
                }`}
                onToggle={(isOpen) => setSolutionsOpen(isOpen)} // Update state on toggle
              >
                <NavDropdown.Item
                 as={Link} to="/data-cloud"
                  active={location.pathname === "/data-cloud"}
                >
                  Salesforce Data Cloud
                </NavDropdown.Item>
                <NavDropdown.Item
                 as={Link} to="/marketing-cloud"
                  active={location.pathname === "/marketing-cloud"}
                >
                  Salesforce Marketing Cloud
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/marketing-cloud-personalization"
                  active={location.pathname === "/marketing-cloud-personalization"}
                >
                  Salesforce Marketing Cloud Personalization
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/agent-force" active={location.pathname === "/agent-force"}>
                Salesforce Agent Force
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/about" active={location.pathname === "/about"}>
                About Us
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" active={location.pathname === "/contact"}>
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Mobile Offcanvas */}
      <Offcanvas show={show} onHide={handleClose} className="d-lg-none">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link as={Link} to="/" active={location.pathname === "/"}>
              Home
            </Nav.Link>
            {/* Accordion for Mobile Dropdowns */}
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Services</Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    as={Link} to="/data-cloud"
                    active={location.pathname === "/data-cloud"}
                  >
                    Salesforce Data Cloud
                  </Nav.Link>
                  <Nav.Link as={Link} to="/marketing-cloud" active={location.pathname === "/marketing-cloud"}>
                  Salesforce Marketing Cloud
                  </Nav.Link>
                  <Nav.Link as={Link} to="/marketing-cloud-personalization" active={location.pathname === "/marketing-cloud-personalization"}>
                  Salesforce Marketing Cloud Personalization
                  </Nav.Link>
                  <Nav.Link as={Link} to="/agent-force" active={location.pathname === "/agent-force"}>
                  Salesforce Agent Force
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Nav.Link as={Link} to="/about" active={location.pathname === "/about"}>
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" active={location.pathname === "/contact"}>
              Contact Us
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
